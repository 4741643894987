import { AppLanguages } from '../types'

const englishLanguage: AppLanguages = {
  hw: 'Hello World',
  email: 'Email',
  password: 'Password',
  welcome: 'Welcome',
  signup: 'Sign Up',
  login: 'Login',
  logout: 'Logout',
  emailAddress: 'Email Address',
  firstname: 'First Name',
  lastname: 'Last Name',
  phone: 'Phone',
  active: 'Active',
  company: 'Company',
  companies: 'Companies',
  user: 'User',
  users: 'Users',
  profile: 'Profile',
  settings: 'Settings',
  companySettings: 'Company Settings',
  address: 'Address',
  addresses: 'Address',
  employee: 'Employee',
  employees: 'Employees',
  owner: 'Owner',
  owners: 'Owners',
  amount: 'Amount',
  createdAt: 'Created At',
  time: 'Time',
  type: 'Type',
  description: 'Description',
  viewAll: 'View All',
  view: 'View',
  city: 'City',
  zipCode: 'Zip Code',
  street: 'Street',
  streetNumber: 'Street Number',
  country: 'Country',
  companyName: 'Company Name',
  next: 'Next',
  prev: 'Previous',
  name: 'Name',
  role: 'Role',
  errorLogin: 'Error With the Email Or Password',
  validateEmail: 'The Email is not valid',
  noUsers: 'There is no Users',
  firstPage: 'First Page',
  lastPage: 'Last Page',
  fullName: 'Full Name',
  userId: 'User ID',
  roleUSER: 'User',
  roleOWNER: 'Owner',
  id: 'ID',
  new: 'New',
  newUser: 'New User',
  successfulLogin: 'Successful Login',
  somethingWrong: 'Something is going wrong',
  done: 'Done',
  amountShouldBeMoreThenZero: 'The Amount Should be more then Zero',
  allFieldRequired: 'All Field are required',
  userInfo: 'User Information',
  repeatPassword: 'Repeat Password',
  createNewUser: 'Create New User',
  servicePackage: 'Service Package',
  servicePackages: 'Service Packages',
  price: 'Price',
  archives: 'Archives',
  archive: 'Archive',
  year: 'Year',
  month: 'Month',
  years: 'Years',
  months: 'Months',
  date: 'Date',
  dateTime: 'Date & Time',
  close: 'Close',
  exit: 'Exit',
  selectYear: 'Select Year',
  selectMonth: 'Select Month',
  language: 'Language',
  currency: 'Currency',
  updateSuccess: 'The Data Updated Successfully',
  editUser: 'Edit User',
  notFound: 'Not Found',
  changeImage: 'Change Image',
  newLogo: 'New Logo',
  deleteCurrentImage: 'Delete Current Image',
  subscriptionDetails: 'Subscription Details',
  download: 'Download',
  editCompany: 'Edit Company',
  edit: 'Edit',
  refresh: 'Refresh',
  nextMonthSubscription: 'Next Month Subscription',
  yes: 'Yes',
  no: 'No',
  changePassword: 'Change Password',
  saveChanges: 'Save Changes',
  passwordConfirmation: 'Password Confirmation',
  checkPasswordAgain: 'Please Check The password again!',
  inactive: 'Inactive',
  activate: 'Activate',
  deactivate: 'Deactivate',
  inactiveUserMessage: 'Please note that this is inactive user',
  validateCompanyName: 'Please make sure that the company name is validated',
  packageName: 'Package Name',
  packagePrice: 'Package Price',
  confirmYourEmailSignup:
    'Thank your for registration, Please Confirm Your email',
  unknownError: 'Unknown Error',
  emailVerified:
    'Your Email is Successfully verified, You can now Login to your account',
  verifyPhoneNumber: 'Please Verify Your Phone Number',
  verificationToken: 'Verification Token',
  verificationTokenSentTo: 'The Verification Token is sent to',
  confirm: 'Confirm',
  exportToPdf: 'Export To PDF',
  dashboard: 'Dashboard',
  registerAt: 'Register At',
  lastSeen: 'Last Seen',
  informUserByEmail: 'Inform The User by sending the verification code to the email',
  customer: 'Customer',
  customers: 'Customers',
  newCustomer: 'New Customer',
  editCustomer: 'Edit Customer',
  noCustomers: 'There is No Customer',
  gender: 'Gender',
  genderMALE: 'Male',
  genderFEMALE: 'Female',
  customerId: 'Customer ID',
  createNewCustomer: 'Create New Customer',
  createdBy: 'Created By',
  contract: 'Contract',
  contracts: 'Contracts',
  newContract: 'New Contract',
  select: 'Select',
  searchCustomer: 'Search Customer',
  selectCustomer: 'Select Customer',
  genderWelcomeMALE: 'Mr.',
  genderWelcomeFEMALE: 'Ms.',
  moveInAddress: 'Move In Address',
  moveOutAddress: 'Move Out Address',
  add: 'Add',
  floor: 'Floor',
  elevator: 'Elevator',
  relocation: 'Relocation',
  relocationInfo: 'Relocation Information',
  startDateTime: 'Start Date/Time',
  hours: 'Hours',
  expectedTime: 'Expected Time',
  returnTrip: 'Return Trip',
  workers: 'Workers',
  vehicles: 'Vehicles',
  hourPrice: 'Hour Price',
  expectedPrice: 'Expected Price',
  includeMWST: 'Include MwSt',
  flatRate: 'Flat Rate',
  flatRatePrice: 'Flat Rate Price',
  priceProM3: 'Price Pro m3',
  trash: 'Trash',
  trashFlatRatePrice: 'Flat Rate Price',
  flatRatePriceNote: 'if you define the flat rate price, the price pro m3 will be ignored',
  transportExtraNotes: 'Extra Notes',
  trashExtraNotes: 'Trash Extra Notes',
  cleaningExtraNotes: 'Cleaning Extra Notes',
  cleaning: 'Cleaning',
  cleaningDateTime: 'Cleaning Date/Time',
  open: 'Open',
  selectDateTime: 'Select Date / Time',
  rooms: 'Rooms',
  cleaningPrice: 'Cleaning Price',
  createNewContract: 'Create New Contract',
  noCleaning: 'No Cleaning',
  status: 'Status',
  statusOPEN: 'Open',
  statusACCEPTED: 'Accepted',
  statusPENDING: 'Pending',
  contractDateTime: 'Contract Date / Time',
  hourly: 'Hourly',
  noContracts: 'There is no Contracts',
  calendar: 'Calendar',
  addVehicle: 'Add Vehicle',
  vehicleName: 'Vehicle Name',
  vehicleAmount: 'Vehicle Amount',
  report: 'Report',
  relocationPrice: 'Relocation Price',
  includeMWSTPriceNote: 'This Price is INCLUDE MwSt 7.7 %',
  excludeMWSTPriceNote: 'This Price is EXCLUDE MwSt 7.7 %',
  workersFood: 'Workers Food',
  priceType: 'Price Type',
  maxPrice: 'Max Price',
  from: 'From',
  to: 'To',
  delete: 'Delete',
  buildingType: 'Building Type',
  packaging: 'Packaging',
  packagingInfo: 'Packaging Info',
  contractsCount: 'Contracts Count',
  contractHeader: 'contract Header',
  contractFooter: 'contract Footer',
  website: 'Website',
  emailCanNotEdit: 'To Change The Email, please contact the System Admin',
  customerComingFrom: 'Customer Coming From',
  customerComingFromExample: '( Phone, Visit, In Office)',
  cleaningEndDateTime: 'Cleaning End Date Time',
  endDateTime: 'End Date/Time',
  deleteEmployee: 'Delete Employee',
  deleteCustomer: 'Delete Customer',
  deleteEmployeeConfirm: 'Are You sure that you want to delete Employee <strong>{name}</strong> ?',
  deleteCustomerConfirm: 'Are You sure that you want to delete Customer <strong>{name}</strong> ?',
  acceptingContract: 'Accepting Contract',
  deleteContract: 'Delete Contract',
  editContract: 'Edit Contract',
  deleteContractNote: 'Are You sure To delete Contract <strong>#{contractId}</strong> <br> for customer: <strong>{customerName}</strong>',
  colors: 'Colors',
  backgroundColor: 'Background Color',
  textColor: 'Text Color',
  updateLogo: 'Update The Logo',
  agbHelp: 'AGB is going to be sent with each contract to the customer as separate attachment',
  agb: 'AGB',
  updateAgb: 'Update AGB',
  viewAgb: 'View AGB',
  openPdf: 'Open PDF',
  sendToCustomer: 'Send To Customer',
  contractHeaderFooterNote: 'You can make a dynamic template for customers by using <strong>{gender_welcome}</strong>, <strong>{name}</strong>, <strong>{email}</strong>, and the app will replace the it with the customer information',
  copyToCompanyEmail: 'Copy To Company Email',
  send: 'Send',
  mailTemplate: 'Mail Template',
  mailTemplates: 'Mail Templates',
  newMailTemplate: 'New Mail Template',
  editMailTemplate: 'Edit Mail Template',
  deleteMailTemplate: 'Delete Mail Template',
  createMailTemplate: 'Create Mail Template',
  mailTitle: 'Mail Title',
  mailBody: 'Mail Body',
  writeNoteContentHere: 'Write Mail Content Here',
  editor: 'Editor',
  preview: 'Preview',
  noMailTemplates: 'No Mail Templates',
  allowedTemplateCode: 'The allowed Template code',
  allowedTemplateCodeNote: 'This Codes will be replaced with the contract and customer and company information',
  deleteMailTemplateConfirmation: 'Are You Sure to delete this Mail Template',
  deleted: 'Deleted',
  selectTemplate: 'Select Template',
  makeOffer: 'Make an Offer',
  makeOfferFor: 'Make an Offer For',
  offerSendSuccess: 'The Offer is send successfully',
  offers: 'Offers',
  openContract: 'Open Contract',
  contractId: 'Contract ID',
  offer: 'Offer',
  emailPreview: 'Email Preview',
  customerEmail: 'Customer Email',
  acceptedAt: 'Accepted At',
  deleteOffer: 'Delete Offer',
  deleteOfferConfirmation: 'Are You sire that you want to delete this offer',
  openContractOffers: 'Open Contract Offers',
  contractOffers: 'Contract Offers',
  openCustomerOffers: 'Open Customer Offers',
  customerOffers: 'Customer Offer',
  noOffers: 'There is no Offers',
  appointment: 'Appointment',
  appointments: 'Appointments',
  createAppointment: 'Create Appointment',
  createNewAppointment: 'Create New Appointment',
  sendAppointmentToCustomer: 'Send Appointment To Customer',
  minutes: 'Minutes',
  duration: 'Duration',
  appointmentDateTimeNote: 'The Appointment Time should be more then a hour from now',
  location: 'Location',
  copyToCustomer: 'Copy To Customer',
  appointmentCreatedSuccess: 'The Appointment is created successfully',
  sendToEmail: 'Send To Email',
  noAppointments: 'There is no Appointments',
  specialProductName: 'Special Product Name',
  specialProductCount: 'Special Product Count',
  unitPrice: 'Unit Price',
  addSpecialProduct: 'Add Special Product',
  specialProduct: 'Special Product',
  specialProducts: 'Special Products',
  total: 'Total',
  finalPrice: 'Final Price',
  count: 'Count',
  contractCreatedSuccess: 'the Contract is created Successfully',
  offerCreatedSuccess: 'the Offer is sent Successfully',
  openReceipt: 'Open Receipt',
  searchContract: 'Search Contract',
  searchContracts: 'Search Contracts',
  all: 'All',
  fromDate: 'From Date',
  toDate: 'To Date',
  search: 'Search',
  noContractWithId: 'There is NO Contract with the given ID',
  reset: 'Reset',
  viewEmployeeContracts: 'View Employee Contracts',
  employeeContracts: 'Employee Contracts',
  packagingPriceType: 'Packaging Price Type',
  receiptNote: 'Receipt Note',
  loading: 'Loading',
  discount: 'Discount',
  discounts: 'Discounts',
  discountAmount: 'Discount Amount',
  discountPercent: 'Discount Percent',
  discountNote: 'You Can Only Define the Discount amount or Discount Percent',
  updateContract: 'Update Contract',
  offerConfirmation: 'Offer Confirmation',
  sendAcceptConfirmation: 'Send Accept Confirmation',
  contractTemplates: 'Contract Templates',
  contractTemplate: 'Contract Template',
  newContractTemplate: 'New Contract Template',
  contractTemplateTitle: 'Contract Mail Template',
  contractTemplateFile: 'Contract Template File',
  openContractTemplateNote: 'Write the contract ID here, and then you will see that contract designed with this template.',
  specialProductAutoCompleteNote: 'Just Clicking the name button will insert the name to the form directly.',
  vehicleAutoCompleteNote: 'Just clicking the name button will insert the name to the form directly. ',
  arrivalAndReturnPrice: 'Arrival and Return Trip',
  newDynamicContract: 'New Dynamic Contract',
  packingMaterial: 'Packing Material',
  packingMaterials: 'Packing Materials',
  packingMaterialName: 'Packing Material Name',
  packingMaterialCount: 'Packing Material Count',
  addPackingMaterial: 'Add Packing Material',
  addExtraInput: 'Add Extra Input',
  relocationExtraInfo: 'Relocation Extra Info',
  extraInfo: 'Extra Info',
  default: 'Default',
  acceptContractThankYou: 'Thank you for trusting us, the offer is accepted',
  noContractWithTheGivenLink: 'There is no Contract with the Given Link',
  openCleaningReceipt: 'Open Cleaning Receipt',
  disassembly: 'Furniture disassembly',
  deleteAcceptedContract: 'Delete Accepted Contract',
  onlyAdmin: 'Only admin can do this action',
  notAcceptedContract: 'This Contract is not Accepted, and you can not delete it here',
  trashExtraInfo: 'Trash Extra Info',
  cleaningExtraInfo: 'Cleaning Extra Info',
  accept: 'Accept',
  reject: 'Reject',
  rejectContractThankYou: 'Thank you for trusting us, the offer is rejected',
  statusREJECT: 'Rejected',
  others: 'Others',
  sendEmail: 'Send Email',
  sendEmailTo: 'Send Email To',
  save: 'Save',
  deleteAppointment: 'Delete an Appointment',
  analytics: 'Analytics',
  products: 'Products',
  newProduct: 'New Product',
  product: 'Product',
  productName: 'Product Name',
  deleteProduct: 'Delete Product',
  deleteProductConfirmation: 'Are you sure that you want to delete the product',
  noProducts: 'There is no products',
  editProduct: 'Edit Product',
  itemType: 'Item Type',
  itemType_item: 'Item',
  itemType_product: 'Product',
  itemType_text: 'Text',
  itemType_discount: 'Discount',
  itemType_subtotal: 'Subtotal',
  items: 'Items',
  order: 'Order',
  selectProduct: 'Select Product',
  contractItems: 'Contract Items',
  lang_en: 'English',
  lang_de: 'German',
  lang_fr: 'French',
  lang_it: 'Italian',
  confirmed: 'Confirmed',
  confirmationSent: 'Confirmation Sent',
  confirmedBy: 'Confirmed By',
  lampAssemply: 'Lamp Assemply',
  wallMounting: 'Wall Mounting',
  furnitureLift: 'Furniture Lift',
  typeOfFloor: 'Type of floor',
  typeOfBlinds: 'Type of blinds',
  additionalRooms: 'Additional Rooms',
  washingTowerAvailable: 'Washing Tower is Available',
  fillDowelHoles: 'Fill in the dowel holes',
  highPressureCleaning: 'High Pressure Cleaning',
  areaForPressureCleaning: 'Area for high pressure',
  customerContracts: 'Customer Contracts'
}
export default englishLanguage
