/* eslint-disable @typescript-eslint/no-non-null-assertion */
import axios, { AxiosResponse } from 'axios'
import store from '@/store'
import router from '@/router'
class _BackendConnectionManager {
  readonly backendURL = '/api/';
  readonly apiVersion = 'v1'

  get<T> (url: string, withApiVersion = true): Promise<AxiosResponse<T>> {
    return new Promise((resolve, reject) => {
      axios
        .get<T>(this.backendURL + (withApiVersion ? this.apiVersion + '/' : '') + url, {
          headers: {
            'x-auth': store.state.accessToken!
          }
        })
        .then((data) => {
          // resolve with the received data from the API
          resolve(data)
        })
        .catch(async (error) => {
          // if the auth token is expired
          if (error.response && [423, 401].includes(error.response.status)) {
            try {
              // logout
              await this._logout()
              // push to log in
              await router.push({ name: 'Login' })
            } catch (error) {
              // there is an error when refreshing the token
              reject(error)
            }
          } else {
            // there is an error when getting the data
            reject(error)
          }
        })
    })
  }

  post<T> (
    url: string,
    data: unknown,
    headers: Record<string, string | number | boolean> = {}
  ): Promise<AxiosResponse<T>> {
    return new Promise((resolve, reject) => {
      headers['x-auth'] = store.state.accessToken!
      axios
        .post<T>(this.backendURL + this.apiVersion + '/' + url, data, { headers: headers })
        .then((data) => {
          // resolve with the received data from the API
          resolve(data)
        })
        .catch(async (error) => {
          // if the auth token is expired
          if (error.response && [423, 401].includes(error.response.status)) {
            try {
              // logout
              await this._logout()
              // push to log in
              await router.push({ name: 'Login' })
            } catch (error) {
              // there is an error when refreshing the token
              reject(error)
            }
          } else {
            // there is an error when getting the data
            reject(error)
          }
        })
    })
  }

  put<T> (
    url: string,
    data: unknown,
    headers: Record<string, string | number | boolean> = {}
  ): Promise<AxiosResponse<T>> {
    return new Promise((resolve, reject) => {
      headers['x-auth'] = store.state.accessToken!
      axios
        .put<T>(this.backendURL + this.apiVersion + '/' + url, data, { headers: headers })
        .then((data) => {
          // resolve with the received data from the API
          resolve(data)
        })
        .catch(async (error) => {
          // if the auth token is expired
          if (error.response && [423, 401].includes(error.response.status)) {
            try {
              // logout
              await this._logout()
              // push to log in
              await router.push({ name: 'Login' })
            } catch (error) {
              // there is an error when refreshing the token
              reject(error)
            }
          } else {
            // there is an error when getting the data
            reject(error)
          }
        })
    })
  }

  delete (url: string, data: unknown, headers = { 'x-auth': '' }) {
    return new Promise((resolve, reject) => {
      headers['x-auth'] = store.state.accessToken!
      axios
        .delete(this.backendURL + this.apiVersion + '/' + url, { headers: headers })
        .then((data) => {
          // resolve with the received data from the API
          resolve(data)
        })
        .catch(async (error) => {
          // if the auth token is expired
          if (error.response && [423, 401].includes(error.response.status)) {
            try {
              // logout
              await this._logout()
              // push to log in
              await router.push({ name: 'Login' })
            } catch (error) {
              // there is an error when refreshing the token
              reject(error)
            }
          } else {
            // there is an error when getting the data
            reject(error)
          }
        })
    })
  }

  async openInNewTab (url: string, fileName: string, contentType = 'application/pdf') {
    await this.download(url, fileName, false, contentType)
  }

  async download (url: string, filename: string, download = true, contentType = 'application/pdf') {
    return axios({
      url: this.backendURL + url, // your url
      method: 'GET',
      headers: {
        'x-auth': store.state.accessToken!
      },
      responseType: 'blob' // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data], {
        type: contentType
      }))
      const link = document.createElement('a')
      link.href = url
      if (download) {
        link.setAttribute('download', filename)
      } else {
        link.target = '_blank'
      }
      document.body.appendChild(link)
      link.click()
    })
  }

  async _logout () {
    await store.dispatch('setUser', {})
    await store.dispatch('setAccessToken', '')
    return true
  }
}

export default new _BackendConnectionManager()
