import { Languages } from './types'
import English from './languages/en'
import German from './languages/de'

class Language {
  private currentLanguage: Languages = Languages.English;
  langs = English;

  private static instance: Language = new Language();

  static getInstance (): Language {
    return this.instance
  }

  public changeLanguage (language: Languages): void {
    this.currentLanguage = language
    switch (language) {
      case Languages.English:
        this.langs = English
        break
      case Languages.Germany:
        this.langs = German
        break
      default:
        this.langs = German
        break
    }
  }

  public getCurrentLanguage (): Languages {
    return this.currentLanguage
  }

  public lang (key: string): string {
    return this.langs[key] || `Unknown Word ( ${key} )`
  }
}

export default Language.getInstance()
