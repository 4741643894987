import { AppLanguages } from '../types'

const germanLanguage: AppLanguages = {
  hw: 'Hello World',
  email: 'Email',
  password: 'Password',
  welcome: 'Welcomen',
  signup: 'Anmeldung',
  login: 'Einloggen',
  logout: 'Abmelden',
  emailAddress: 'E-Mail-Addresse',
  firstname: 'Vor Name',
  lastname: 'Nach Name',
  phone: 'Telefon',
  active: 'Aktiv',
  company: 'Firma',
  companies: 'Firmen',
  user: 'Benutzer',
  users: 'Nutzer',
  profile: 'Profil',
  settings: 'Einstellungen',
  companySettings: 'Firmen Einstellungen',
  address: 'Addresse',
  addresses: 'Addressen',
  employee: 'Mitarbeiter',
  employees: 'Mitarbeiteren',
  owner: 'Eigentümer',
  owners: 'Eigentümeren',
  amount: 'Betrag',
  createdAt: 'Erstellt am',
  time: 'Zeit',
  type: 'Typ',
  description: 'Beschreibung',
  viewAll: 'Alle anzeigen',
  view: 'Anzeigen',
  city: 'Stadt',
  zipCode: 'PLZ',
  street: 'Strasse',
  streetNumber: 'Hausnummer',
  country: 'Land',
  companyName: 'Firma Name',
  next: 'Weiter',
  prev: 'Vorherige',
  name: 'Name',
  role: 'Rolle',
  errorLogin: 'Fehler mit der E-Mail oder dem Passwort',
  validateEmail: 'Die E-Mail ist nicht gültig',
  noUsers: 'Es gibt keine Benutzer',
  firstPage: 'Erste Seite',
  lastPage: 'Letzte Seite',
  fullName: 'Name und Vorname',
  userId: 'Benutzer ID',
  roleUSER: 'Benutzer',
  roleOWNER: 'Eigentümer',
  id: 'ID',
  new: 'Neu',
  newUser: 'Neu Benutzer',
  successfulLogin: 'Erfolgreiche Anmeldung',
  somethingWrong: 'Etwas läuft schief',
  done: 'Fertig',
  amountShouldBeMoreThenZero: 'Der Betrag sollte grösser als Null sein',
  allFieldRequired: 'Alle Felder sind erforderlich',
  userInfo: 'Benutzerinformationen',
  repeatPassword: 'Passwort wiederholen',
  createNewUser: 'Neuen Benutzer anlegen',
  servicePackage: 'Service-Paket',
  servicePackages: 'Service-Pakete',
  price: 'Preis',
  archives: 'Archiv',
  archive: 'Archiv',
  year: 'Jahr',
  month: 'Monat',
  years: 'Jahre',
  months: 'Monate',
  date: 'Datum',
  dateTime: 'Datum und Uhrzeit',
  close: 'Schliessen',
  exit: 'Exit',
  selectYear: 'Jahr auswählen',
  selectMonth: 'Monat auswählen',
  language: 'Sprache',
  currency: 'Währung',
  updateSuccess: 'Die Daten wurden erfolgreich aktualisiert',
  editUser: 'Benutzer bearbeiten',
  notFound: 'Nicht gefunden',
  changeImage: 'Bild ändern',
  newLogo: 'Neues Logo',
  deleteCurrentImage: 'Aktuelles Bild löschen',
  subscriptionDetails: 'Details zum Abonnement',
  download: 'Herunterladen',
  editCompany: 'Firma bearbeiten',
  edit: 'Bearbeiten',
  refresh: 'Auffrischen',
  nextMonthSubscription: 'Nächstes Monatsabonnement',
  yes: 'Ja',
  no: 'Nein',
  changePassword: 'Passwort ändern',
  saveChanges: 'Änderungen speichern',
  passwordConfirmation: 'Passwort-Bestätigung',
  checkPasswordAgain: 'Please Check The password again!',
  inactive: 'Inaktiv',
  activate: 'Aktivieren Sie',
  deactivate: 'Deaktivieren Sie',
  inactiveUserMessage: 'Bitte beachten Sie, dass dies ein inaktiver Benutzer ist',
  validateCompanyName: 'Bitte vergewissern Sie sich, dass der Firmenname validiert ist',
  packageName: 'Name des Pakets',
  packagePrice: 'Paketpreis',
  confirmYourEmailSignup:
    'Vielen Dank für Ihre Anmeldung, bitte bestätigen Sie Ihre E-Mail',
  unknownError: 'Unbekannter Fehler',
  emailVerified:
    'Ihre E-Mail wurde erfolgreich verifiziert, Sie können sich jetzt bei Ihrem Konto anmelden.',
  verifyPhoneNumber: 'Bitte überprüfen Sie Ihre Telefonnummer',
  verificationToken: 'Verifizierungs-Token',
  verificationTokenSentTo: 'Das Verifizierungs-Token wird gesendet an',
  confirm: 'Bestätigen Sie',
  exportToPdf: 'In PDF exportieren',
  dashboard: 'Dashboard',
  registerAt: 'Anmelden unter',
  lastSeen: 'Zuletzt gesehen',
  informUserByEmail: 'Informieren Sie den Benutzer, indem Sie den Verifizierungscode an die E-Mail senden',
  customer: 'Kunde',
  customers: 'Kunden',
  newCustomer: 'Neuer Kunde',
  editCustomer: 'Kunde bearbeiten',
  noCustomers: 'Es gibt keinen Kunden',
  gender: 'Gender',
  genderMALE: 'Männlich',
  genderFEMALE: 'Weiblich',
  customerId: 'Kunde ID',
  createNewCustomer: 'Neuen Kunden anlegen',
  createdBy: 'Erstellt von',
  contract: 'Vertrag',
  contracts: 'Verträge',
  newContract: 'Neuer Vertrag',
  select: 'Wählen Sie',
  searchCustomer: 'Suche Kunde',
  selectCustomer: 'Kunde auswählen',
  genderWelcomeMALE: 'Herr',
  genderWelcomeFEMALE: 'Frau',
  moveInAddress: 'Einzugsadresse',
  moveOutAddress: 'Auszugsadresse',
  add: 'hinzufügen',
  floor: 'Etage',
  elevator: 'Lift',
  relocation: 'Umzug',
  relocationInfo: 'Informationen zum Umzug',
  startDateTime: 'Start Datum/Uhrzeit',
  hours: 'Stunden',
  expectedTime: 'Geschätzter Aufwand',
  returnTrip: 'Anfahrt/Rückfahrt',
  workers: 'Mitarbeiter',
  vehicles: 'Liferwagen',
  hourPrice: 'Preis pro Stunde',
  expectedPrice: 'Geschätzte Kosten',
  includeMWST: 'inklusive MwSt',
  flatRate: 'Pauschal',
  flatRatePrice: 'Pauschalpreis',
  priceProM3: 'Preis Pro m3',
  trash: 'Entsorgung',
  trashFlatRatePrice: 'Pauschalpreis',
  flatRatePriceNote: 'wenn Sie den Pauschalpreis festlegen, wird der Preis pro m3 ignoriert',
  transportExtraNotes: 'Bemerkungen',
  trashExtraNotes: 'Bemerkungen',
  cleaningExtraNotes: 'Bemerkungen',
  cleaning: 'Reinigung',
  cleaningDateTime: 'Reinigung Datum/Uhrzeit',
  open: 'Öffen',
  selectDateTime: 'Datum / Uhrzeit auswählen',
  rooms: 'Zimmer',
  cleaningPrice: 'Reinigung Preis',
  createNewContract: 'Neuen Vertrag erstellen',
  noCleaning: 'Keine Reinigung',
  status: 'Status',
  statusOPEN: 'Öffen',
  statusACCEPTED: 'Akzeptiert',
  statusPENDING: 'Anhängig',
  contractDateTime: 'Vertrags Datum / Uhrzeit',
  hourly: 'Stündlich',
  noContracts: 'Es gibt keine Verträge',
  calendar: 'Kalender',
  addVehicle: 'Fahrzeug hinzufügen',
  vehicleName: 'Fahrzeug Name',
  vehicleAmount: 'Anzahl der Fahrzeuge',
  report: 'Report',
  relocationPrice: 'Umzugs-Preis',
  includeMWSTPriceNote: 'Dieser Preis ist INKLUSIVE MwSt 7.7 %',
  excludeMWSTPriceNote: 'Dieser Preis ist EXKLUSIV MwSt 7.7 %',
  workersFood: 'Spese',
  priceType: 'Preis Typ',
  maxPrice: 'Kostendach',
  from: 'Von',
  to: 'Nach',
  delete: 'Löschen',
  buildingType: 'Gebäude',
  packaging: 'Einpacken',
  packagingInfo: 'Einpacken-Infos',
  contractsCount: 'Verträge Anzahl',
  contractHeader: 'Vertrag Kopfzeile',
  contractFooter: 'Vertrag Fusszeile',
  website: 'Website',
  emailCanNotEdit: 'Um die E-Mail zu ändern, wenden Sie sich bitte an den Systemadministrator',
  customerComingFrom: 'Kunde kommend von',
  customerComingFromExample: '( Telefon, Besuch, im Büro )',
  cleaningEndDateTime: 'Abgabe Termin',
  endDateTime: 'Abgabe Termin',
  deleteEmployee: 'Mitarbeiter löschen',
  deleteCustomer: 'Kunde löschen',
  deleteEmployeeConfirm: 'Sind Sie sicher, dass Sie Mitarbeiter <strong>{name}</strong> löschen möchten ?',
  deleteCustomerConfirm: 'Sind Sie sicher, dass Sie Kunde <strong>{name}</strong> löschen möchten ?',
  acceptingContract: 'Akzeptiert des Vertrags',
  deleteContract: 'Vertrag löschen',
  editContract: 'Vertrag bearbeiten',
  deleteContractNote: 'Sind Sie sicher, dass Sie den Vertrag <strong>#{contractId}</strong> löschen wollen <br> für Kunden: <strong>{customerName}</strong>',
  colors: 'Farben',
  backgroundColor: 'Hintergrundfarbe',
  textColor: 'Textfarbe',
  updateLogo: 'Das Logo aktualisieren',
  agbHelp: 'Die AGB werden dem Kunden mit jedem Vertrag als separate Anlage zugesandt.',
  agb: 'AGB',
  updateAgb: 'AGB aktualisieren',
  viewAgb: 'AGB ansehen',
  openPdf: 'PDF Öffnen',
  sendToCustomer: 'An Kunden senden',
  contractHeaderFooterNote: 'Sie können eine dynamische Vorlage für Kunden erstellen, indem Sie <strong>{gender_welcome}</strong>, <strong>{name}</strong>, <strong>{email}</strong> verwenden, und die App ersetzt sie durch die Kundeninformationen',
  copyToCompanyEmail: 'Kopie an Firma-E-Mail',
  send: 'Senden',
  mailTemplate: 'Mail-Vorlage',
  mailTemplates: 'Mail-Vorlagen',
  newMailTemplate: 'Neue Mail-Vorlage',
  editMailTemplate: 'Mail-Vorlage bearbeiten',
  deleteMailTemplate: 'Mail-Vorlage löschen',
  createMailTemplate: 'Mailvorlage erstellen',
  mailTitle: 'E-Mail Titel',
  mailBody: 'E-Mail Body',
  writeNoteContentHere: 'Mail-Inhalt hier schreiben',
  editor: 'Editor',
  preview: 'Vorschau',
  noMailTemplates: 'Keine Mail-Vorlagen',
  allowedTemplateCode: 'Der zulässige Vorlagencode',
  allowedTemplateCodeNote: 'Diese Codes werden durch den Vertrag und die Kunden- und Unternehmensinformationen ersetzt',
  deleteMailTemplateConfirmation: 'Sind Sie sicher, dass Sie diese E-Mail-Vorlage löschen möchten?',
  deleted: 'Gelöscht',
  selectTemplate: 'Vorlage auswählen',
  makeOffer: 'Angebot erstellen',
  makeOfferFor: 'Angebot erstellen für',
  offerSendSuccess: 'Das Angebot wurde erfolgreich gesendet',
  offers: 'Angebote',
  openContract: 'Vertrag anzeigen',
  contractId: 'Vertrag ID',
  offer: 'Angebot',
  emailPreview: 'E-Mail-Vorschau',
  customerEmail: 'Kunden-E-Mail',
  acceptedAt: 'Akzeptiert am',
  deleteOffer: 'Angebot löschen',
  deleteOfferConfirmation: 'Sind Sie sicher, dass Sie dieses Angebot löschen möchten',
  openContractOffers: 'Vertragsangebote anzeigen',
  contractOffers: 'Vertragsangebote',
  openCustomerOffers: 'Kundenangebote anzeigen',
  customerOffers: 'Kundenangebote',
  noOffers: 'Es gibt keine Angebote',
  appointment: 'Termin',
  appointments: 'Termine',
  createAppointment: 'Termin erstellen',
  createNewAppointment: 'Termin erstellen',
  sendAppointmentToCustomer: 'Termin an Kunden senden',
  minutes: 'Minuten',
  duration: 'Dauer',
  appointmentDateTimeNote: 'Der Termin sollte in mehr als einer Stunde sein',
  location: 'Standort',
  copyToCustomer: 'Kopie an Kunde',
  appointmentCreatedSuccess: 'Der Termin wurde erfolgreich erstellt',
  sendToEmail: 'An E-Mail senden',
  noAppointments: 'Es gibt keine Termine',
  specialProductName: 'Spezieller Produktname',
  specialProductCount: 'Spezielle Produktanzahl',
  unitPrice: 'Stückpreis',
  addSpecialProduct: 'Spezialprodukt hinzufügen',
  specialProduct: 'Spezialprodukt',
  specialProducts: 'Spezialprodukte',
  total: 'Total',
  finalPrice: 'Endpreis',
  count: 'Anzahl',
  contractCreatedSuccess: 'der Vertrag wird erfolgreich erstellt',
  offerCreatedSuccess: 'das Angebot wird erfolgreich versandt',
  openReceipt: 'Quittung öffen',
  searchContract: 'Suche Vertrag',
  searchContracts: 'Verträge suchen',
  all: 'Alle',
  fromDate: 'Von Datum',
  toDate: 'Bis Datum',
  search: 'Suche',
  noContractWithId: 'Es gibt KEINEN Vertrag mit der angegebenen ID',
  reset: 'Zurücksetzen',
  viewEmployeeContracts: 'Mitarbeiterverträge ansehen',
  employeeContracts: 'Verträge für Mitarbeiter',
  packagingPriceType: 'Einpackung Preis Typ',
  receiptNote: 'Empfangsbestätigung',
  loading: 'Laden',
  discount: 'Rabatt',
  discounts: 'Rabatte',
  discountAmount: 'Rabatt-Betrag',
  discountPercent: 'Rabatt in Prozent',
  discountNote: 'Sie können nur den Rabattbetrag oder den Rabattprozentsatz festlegen.',
  updateContract: 'Vertrag aktualisieren',
  offerConfirmation: 'Bestätigung des Angebots',
  sendAcceptConfirmation: 'Auftragbestätigung senden',
  contractTemplates: 'Vertragsvorlagen',
  contractTemplate: 'Vertragsvorlage',
  newContractTemplate: 'Neue Vertragsvorlage',
  contractTemplateTitle: 'Vertragspost Vorlage',
  contractTemplateFile: 'Vertragsvorlage Datei',
  openContractTemplateNote: 'Geben Sie hier die Vertragskennung ein, und Sie werden den mit dieser Vorlage erstellten Vertrag sehen.',
  specialProductAutoCompleteNote: 'Mit einem Klick auf die Schaltfläche Name wird der Name direkt in das Formular eingefügt.',
  vehicleAutoCompleteNote: 'Mit einem Klick auf die Schaltfläche Name wird der Name direkt in das Formular eingefügt.',
  arrivalAndReturnPrice: 'Ankunft und Rückreise',
  newDynamicContract: 'Neuer dynamischer Vertrag',
  packingMaterial: 'Verpackungsmaterial',
  packingMaterials: 'Verpackungsmaterialien',
  packingMaterialName: 'Verpackungsmaterial Name',
  packingMaterialCount: 'Anzahl der Verpackungsmaterialien',
  addPackingMaterial: 'Verpackungsmaterial hinzufügen',
  addExtraInput: 'Extra Eingabe hinzufügen',
  relocationExtraInfo: 'Umzug Extra Info',
  extraInfo: 'Extra Info',
  default: 'Standard',
  acceptContractThankYou: 'Danke für Ihr Vertrauen, das Angebot ist angenommen',
  noContractWithTheGivenLink: 'Es gibt keinen Vertrag mit dem angegebenen Link',
  openCleaningReceipt: 'Reinigungsbeleg öffnen',
  disassembly: 'Möbel Ab-Aufbau',
  deleteAcceptedContract: 'Akzeptierten Verträg löschen',
  onlyAdmin: 'Nur der Eigentümer kann diese Aktion durchführen',
  notAcceptedContract: 'Dieser Vertrag wird nicht akzeptiert, und Sie können ihn hier nicht löschen',
  trashExtraInfo: 'Entsorgung Extra Info',
  cleaningExtraInfo: 'Reinigung Extra Info',
  accept: 'Akzeptieren',
  reject: 'Ablehnen',
  rejectContractThankYou: 'Danke für Ihr Vertrauen, das Angebot ist abgelehnt',
  statusREJECT: 'Abgelehnt',
  others: 'Andere',
  sendEmail: 'E-Mail senden',
  sendEmailTo: 'E-Mail senden an',
  save: 'speichern',
  deleteAppointment: 'Termin löschen',
  analytics: 'Analytik',
  products: 'Produkte',
  newProduct: 'Neues Produkt',
  product: 'Produkt',
  productName: 'Produktname',
  deleteProduct: 'Produkt löschen',
  deleteProductConfirmation: 'Sind Sie sicher, dass Sie das Produkt löschen möchten?',
  noProducts: 'Es gibt keine Produkte',
  editProduct: 'Produkt bearbeiten',
  itemType: 'Artikel Typ',
  itemType_item: 'Artikel',
  itemType_product: 'Produkt',
  itemType_text: 'Text',
  itemType_discount: 'Rabatt',
  itemType_subtotal: 'Zwischensumme',
  items: 'Artikel',
  order: 'POS',
  selectProduct: 'Produkt auswählen',
  contractItems: 'Vertragsgegenstände',
  lang_en: 'Englisch',
  lang_de: 'Deutsch',
  lang_fr: 'Französisch',
  lang_it: 'Italienisch',
  confirmed: 'Bestätigt',
  confirmationSent: 'Bestätigung gesendet',
  confirmedBy: 'Bestätigt von',
  lampAssemply: 'Lampenmontage',
  wallMounting: 'Wandmontage',
  furnitureLift: 'Möbellift',
  typeOfFloor: 'Art der Bodenbeläge',
  typeOfBlinds: 'Art der Storen',
  additionalRooms: 'Zusätzliche Räume',
  washingTowerAvailable: 'Waschturm vorhanden',
  fillDowelHoles: 'Dübellöcher zuspachteln',
  highPressureCleaning: 'Hochdruckreinigen',
  areaForPressureCleaning: 'Fläche für den Hochdruck',
  customerContracts: 'Kundenverträge'
}
export default germanLanguage
