import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw
} from 'vue-router'
import BackendConnectionManager from '@/includes/BackendConnectionManager'
import User from '@/interfaces/user'
import store from '@/store'
import Language from '@/includes/language'
import { Languages } from '@/includes/types'

async function beforeEach (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  // const userInfo = store.state.user;
  const isLogin = store.state.isLogin
  const accessToken = store.state.accessToken
  if (!isLogin && !accessToken) {
    if (
      to.path !== '/login' &&
      to.path !== '/signup' &&
      !to.path.startsWith('/verify/') &&
      !to.path.startsWith('/accept-contract/')
    ) {
      return next({ name: 'Login', query: { redirect: to.fullPath } })
    }
  } else if (!isLogin && accessToken) {
    try {
      const response = await BackendConnectionManager.get<User>('auth/whoami')
      await store.dispatch('setUser', response.data)
      let appLanguage = Languages.English
      if (response.data.language === 'de') {
        appLanguage = Languages.Germany
      }
      Language.changeLanguage(appLanguage)
    } catch (error) {
      console.log('router error', error)
      throw error
    }
  }
  next()
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Auth/Login.vue')
  },
  {
    path: '/logout',
    name: 'Logout',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Auth/Logout.vue')
  },
  {
    path: '/products/new',
    name: 'NewProduct',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/products/new.vue')
  },
  {
    path: '/products',
    name: 'Products',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/products/index.vue')
  },
  {
    path: '/employees',
    name: 'Employees',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Employees/EmployeesList.vue')
  },
  {
    path: '/employees/new',
    name: 'NewEmployee',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Employees/newEmployee.vue')
  },
  {
    path: '/employees/:employeeId',
    name: 'Employee',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Employees/viewEmployee.vue')
  },
  {
    path: '/employees/:employeeId/edit',
    name: 'EditEmployee',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Employees/EditEmployee.vue')
  },
  {
    path: '/customers',
    name: 'Customers',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Customers/CustomersList.vue')
  },
  {
    path: '/customers/new',
    name: 'NewCustomer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Customers/newCustomer.vue')
  },
  {
    path: '/customers/:customerId',
    name: 'Customer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Customers/viewCustomer.vue')
  },
  {
    path: '/customers/:customerId/edit',
    name: 'EditCustomer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Customers/EditCustomer.vue')
  },
  {
    path: '/contracts/employee/:employeeId',
    name: 'EmployeeContract',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Contracts/employeeContracts.vue')
  },
  {
    path: '/contracts/delete-accepted-contract',
    name: 'deleteAcceptedContract',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Contracts/deleteAcceptedContract.vue')
  },
  {
    path: '/contracts/new',
    name: 'NewContract',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Contracts/NewContract.vue')
  },
  {
    path: '/contracts/:contractId/edit',
    name: 'EditContract',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Contracts/EditContract.vue')
  },
  {
    path: '/contracts/:contractId',
    name: 'ViewContract',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Contracts/ViewContract.vue')
  },
  {
    path: '/contracts/',
    name: 'Contracts',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Contracts/ContractList.vue')
  },
  {
    path: '/mail-templates/new',
    name: 'NewMailTemplate',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/MailTemplate/newMailTemplate.vue')
  },
  {
    path: '/mail-templates/:templateId/edit',
    name: 'EditMailTemplate',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/MailTemplate/EditMailTemplate.vue')
  },
  {
    path: '/mail-templates/:templateId',
    name: 'ViewMailTemplate',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/MailTemplate/ViewMailTemplate.vue')
  },
  {
    path: '/mail-templates',
    name: 'MailTemplates',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/MailTemplate/MailTemplateList.vue')
  },
  {
    path: '/contract-templates/new',
    name: 'NewContractTemplate',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/ContractTemplates/newContractTemplate.vue')
  },
  {
    path: '/contract-templates/:templateId',
    name: 'ContractTemplate',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/ContractTemplates/viewContractTemplate.vue')
  },
  {
    path: '/contract-templates',
    name: 'ContractTemplates',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/ContractTemplates/contractTemplateList.vue')
  },
  {
    path: '/company',
    name: 'ViewCompany',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/company/ViewCompany.vue')
  },
  {
    path: '/company/edit',
    name: 'EditCompany',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/company/EditCompany.vue')
  },
  {
    path: '/accept-contract/:contractId/:offerId/:offerToken',
    name: 'AcceptContract',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Contracts/AcceptContract.vue')
  },
  {
    path: '/offers/contract/:contractId',
    name: 'ContractsOffer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/offers/ContractsOffer.vue')
  },
  {
    path: '/offers/customer/:customerId',
    name: 'CustomerOffers',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/offers/customerOffers.vue')
  },
  {
    path: '/offers/:offerId',
    name: 'ViewOffer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/offers/ViewOffer.vue')
  },
  {
    path: '/offers',
    name: 'Offers',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/offers/OffersList.vue')
  },
  {
    path: '/appointments/:appointmentId',
    name: 'Appointment',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Appointments/ViewAppointment.vue')
  },
  {
    path: '/appointments',
    name: 'Appointments',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Appointments/AppointmentsList.vue')
  },
  {
    path: '/search/contracts',
    name: 'SearchContracts',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Search/Contracts.vue')
  },
  {
    path: '/search/customers',
    name: 'SearchCustomers',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Search/Customer.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(beforeEach)

export default router
