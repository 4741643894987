import { InjectionKey } from 'vue'
import { createStore, Store } from 'vuex'
import User from '@/interfaces/user'
import { Languages } from '@/includes/types'
const myStorage = localStorage
if (!myStorage.getItem('TransportCrm')) {
  myStorage.setItem('TransportCrm', '')
}
if (!myStorage.getItem('TransportCrmLanguage')) {
  myStorage.setItem('TransportCrmLanguage', Languages.English)
}

// define your typings for the store state
export interface State {
  user: User | null;
  isLogin: boolean;
  accessToken: string | null;
  language: Languages;
}

// define injection key
export const key: InjectionKey<Store<State>> = Symbol('Key')

export default createStore<State>({
  state: {
    user: {} as User,
    isLogin: false,
    accessToken: myStorage.getItem('TransportCrm'),
    language: Languages.English
  },
  mutations: {
    SET_USER (state, user: User) {
      state.user = user
      state.isLogin = !!user.uuid
    },
    SET_ACCESS_TOKEN (state, accessToken: string) {
      state.accessToken = accessToken
      myStorage.setItem('TransportCrm', accessToken)
    },
    SET_LANGUAGE (state, language: Languages) {
      state.accessToken = language
    }
  },
  actions: {
    setUser (context, user: User) {
      context.commit('SET_USER', user)
    },
    setAccessToken (context, accessToken: string) {
      context.commit('SET_ACCESS_TOKEN', accessToken)
    },
    setLanguage (context, language: Languages) {
      context.commit('SET_LANGUAGE', language)
    }
  },
  modules: {}
})
