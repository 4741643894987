export enum UserRoles {
  User = 'user',
  Owner = 'owner',
  Admin = 'admin',
  ReSeller = 'reseller',
}

export enum Languages {
  English = 'en',
  Germany = 'de',
}

export interface AppLanguages {
  [key: string]: string;
}

export interface LanguagesUsage {
  lang(key: string): string;
}
