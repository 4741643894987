
import { Options, Vue } from 'vue-class-component'
import { computed, watch } from 'vue'
import store from '@/store'
import User from '@/interfaces/user'
import { useRoute } from 'vue-router'
import Language from '@/includes/language'
import jquery from '@/includes/jquery'
import { UserRoles } from '@/includes/types'
import Router from '@/router'

@Options({
  components: {}
})
export default class Application extends Vue {
  isLogin = false;
  language = Language;
  userInfo = {} as User;
  currentRouteName = '/';
  executeLogout = 0;
  public async mounted (): Promise<void> {
    const isLogin = computed<boolean | null>(() => store.state.isLogin)
    const user = computed<User | null>(() => store.state.user)
    const route = useRoute()
    const path = computed(() => route.path)
    if (user.value) {
      this.userInfo = user.value
    }
    watch(isLogin, (isLogin: boolean | null) => {
      this.isLogin = isLogin as boolean
    })
    watch(user, (newV: User | null) => {
      this.userInfo = newV as User
      if (this.userInfo.uuid) {
        window.onmousemove = () => this.watchUserIsNotActive()
        this.watchUserIsNotActive()
      } else {
        window.onmousemove = null
      }
      setTimeout(() => {
        const navItems = document.querySelectorAll('.nav-item')
        if (navItems.length > 0) {
          for (const navItem of navItems) {
            if (navItem.classList[1] !== 'dropdown') {
              navItem.addEventListener('click', () => {
                jquery('#sidebarMenu').collapse('hide')
              })
            }
          }
        }
      }, 1000)
    })
    watch(path, async (newPath: string) => {
      if (this.userInfo.role === UserRoles.User) {
        if (
          ['/employees', '/employees/'].includes(newPath) &&
          !newPath.endsWith('/edit')
        ) {
          await Router.push({ name: 'Home' })
          return
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.currentRouteName = newPath!
    })
    setTimeout(() => {
      const navItems = document.querySelectorAll('.nav-item')
      if (navItems.length > 0) {
        for (const navItem of navItems) {
          if (navItem.classList[1] !== 'dropdown') {
            navItem.addEventListener('click', () => {
              jquery('#sidebarMenu').collapse('hide')
            })
          }
        }
      }
    }, 1000)
    const loadingSpinner = document.getElementById('indexLoadingSpinner')
    if (loadingSpinner) {
      loadingSpinner.style.display = 'none'
    }
  }

  public watchUserIsNotActive (): void {
    clearTimeout(this.executeLogout)
    this.executeLogout = window.setTimeout(async () => {
      return await Router.push({ name: 'Logout', query: { redirect: Router.currentRoute.value.fullPath } })
    }, 15 * 60 * 1000)
  }
}
